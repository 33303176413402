import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({
  admin = false, user, component: Component, ...restOfProps
}) => {
  const isAuth = admin ? user.email === 'enmanuelmag@gmail.com' : !!user.email;
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuth ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
