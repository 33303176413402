import React from 'react';
import {
  Route,
  Switch,
  BrowserRouter as Router
} from 'react-router-dom';

import ProtectedRoute from './protectRoute';

import User from '../components/user';
import Login from '../components/login';
import Admin from '../components/admin';

const Routes = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Login} />
      <ProtectedRoute exact path="/user" component={User} />
      <ProtectedRoute admin exact path="/admin" component={Admin} />
    </Switch>
  </Router>
);

export default Routes;
