import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, DatePicker, DatePickerInput, Form, InlineLoading, Modal } from 'carbon-components-react';

import { ArrowRight16 } from '@carbon/icons-react';
import { parseError } from '../../../utils';
import { thunks as userThunks } from '../../../redux/actions/thunks/user';

import './index.scss';
import moment from 'moment';

const RegisterModal = ({
  user = [], visible, close, updatePayUser, loadUsersHanlder
}) => {
  const [error, setError] = useState();
  const [loading, setloading] = useState(false);
  const [sucess, setSucess] = useState(false);
  const [newDate, setNewDate] = useState();
  const updatePay = () => {
    setSucess(false);
    setloading(true);
    setError();
    const email = user.find(c => c.id.endsWith('email'))?.value;
    return updatePayUser({ email, newDate })
      .then(() => {
        setloading(false);
        setSucess(true);
        loadUsersHanlder();
      }).catch((err) => {
        setloading(false);
        setError(err);
      });
  };
  return (
    <Modal
      passiveModal
      open={!!visible}
      modalHeading={'Edit user'}
      className="cd-modal-trans"
      onRequestClose={close}
    >
      <Form noValidate>
        {
          error &&
          <div className='cd-form-error cd-mv-16'>
            {parseError(error)}
          </div>
        }
        <div className='cd-mv-16 cd-form-sucess-2'>
          {
            sucess &&
            <p>
              {'Payment update sucessfully'}
            </p>
          }
        </div>
        <div className='cd-mv-32'>
          <p>
            {'Previuos payment: '}
            {user.find(c => c.id.endsWith('ment'))?.value}
          </p>
        </div>
        <div className='cd-mt-16'>
          <DatePicker 
            onChange={(e) => {
              if (e.length) {
                setNewDate(moment(e[0]).toString());
              }
            }}
            dateFormat="d/m/Y" datePickerType="single">
            <DatePickerInput
              placeholder="mm/dd/yyyy"
              labelText="Update last payment"
              id="date-picker-default-id"
            />
          </DatePicker>
        </div>
        <div className='cd-mt-32'>
        </div>
        <div className='cd-mt-32'>
          {
            loading ?
              <InlineLoading description="Updating..." /> :
              <Button
                type='submit'
                kind='primary'
                onClick={() => updatePay()}
                renderIcon={ArrowRight16}>
                {'Update'}
              </Button>
          }
        </div>
      </Form>
    </Modal>
  );
};


const mapDispatchToProps = dispatch => bindActionCreators({
  updatePayUser: userThunks.updatePayUser
}, dispatch);

export default connect(null, mapDispatchToProps)(RegisterModal);