import moment from 'moment';

export const parseError = (error) => {
  if (error) {
    if (typeof error === 'object') {
      if (typeof error.message === 'string') {
        return error.message;
      }
      if (typeof error.error === 'string') {
        return error.error;
      }
      if (typeof error.payload?.message === 'string') {
        return error.payload.message;
      }
      if (typeof error.payload?.error === 'string') {
        return error.payload.error;
      }
      if (!Object.keys(error).length) {
        return 'Something went wrong, please try again';
      }
    }
    if (typeof error === 'string') {
      return error;
    }
  }
  return 'Something went wrong, please try again';
};

export const getDouble = (lastPayment, payMonth) => {
  return (moment().diff(lastPayment, 'months')) * payMonth;
};
