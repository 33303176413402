import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Form, InlineLoading, Modal, TextInput } from 'carbon-components-react';

import { ArrowRight16 } from '@carbon/icons-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { parseError } from '../../../utils';
import { thunks as userThunks } from '../../../redux/actions/thunks/user';
import {
  register as registerSchema
} from '../../../schema';

import './index.scss';
const RegisterModal = ({ visible, close, register, loadUsersHanlder }) => {
  const [error, setError] = useState();
  const [loading, setloading] = useState();
  const [sucess, setSucess] = useState(false);
  const {
    formState: { errors },
    control, handleSubmit
  } = useForm({
    resolver: yupResolver(registerSchema)
  });
  const registerUser = (data) => {
    setSucess(false);
    setloading(true);
    setError();
    return register(data)
      .then(() => {
        setloading(false);
        setSucess(true);
        loadUsersHanlder();
      }).catch((err) => {
        setloading(false);
        setError(err);
      });
  };
  return (
    <Modal
      passiveModal
      open={!!visible}
      modalHeading={'Register user'}
      className="cd-modal-trans"
      onRequestClose={close}
    >
      <Form
        noValidate
        onSubmit={handleSubmit(registerUser)}>
        {
          error &&
          <div className='cd-form-error cd-mv-16'>
            {parseError(error)}
          </div>
        }
        <div className='cd-mv-16 cd-form-sucess-2'>
          {
            sucess &&
            <p>
              {'User registered successfully'}
            </p>
          }
        </div>
        <div className='cd-mt-32'>
          <Controller
            name='email'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextInput
                id='email'
                type='text'
                labelText={'Email'}
                placeholder='example@email.com'
                invalid={!!errors.email}
                invalidText={errors.email?.message}
                {...field}
              />
            )} />
        </div>
        <div className='cd-mt-32'>
          <Controller
            name='password'
            control={control}
            defaultValue=''
            render={({ field }) => (
              <TextInput
                id='password'
                type='text'
                labelText={'Password'}
                placeholder='Insert code sent to your email'
                invalid={!!errors.password}
                invalidText={errors.password?.message}
                {...field}
              />
            )} />
        </div>
        <div className='cd-mt-32'>
          {
            loading ?
              <InlineLoading description="Registering..." /> :
              <Button
                type='submit'
                kind='primary'
                renderIcon={ArrowRight16}>
                {'Register'}
              </Button>
          }
        </div>
      </Form>
    </Modal>
  );
};


const mapDispatchToProps = dispatch => bindActionCreators({
  register: userThunks.register
}, dispatch);

export default connect(null, mapDispatchToProps)(RegisterModal);