import { 
  login, resetPassword, register,
  loadUsers, updatePayUser, deleteUser, loadUser
} from '../../../services/user';
import { actions as userActions } from '../user';

export const thunks = {
  login: (data) => async dispatch => {
    dispatch(userActions.login.start());
    try {
      const user = await login(data);
      dispatch(userActions.login.success(user));
      return user.email;
    } catch (error) {
      console.log('LOGIN ERROR:', error);
      dispatch(userActions.login.failure(error));
      throw error;
    }
  },
  sendEmailReset: (data) => async dispatch => {
    dispatch(userActions.sendEmailReset.start());
    try {
      const user = await resetPassword(data);
      dispatch(userActions.sendEmailReset.success(user));
    } catch (error) {
      console.log('RESET ERROR:', error);
      dispatch(userActions.sendEmailReset.failure(error));
      throw error;
    }
  },
  register: (data) => async dispatch => {
    dispatch(userActions.register.start());
    try {
      const user = await register(data);
      dispatch(userActions.register.success(user));
      return user.email;
    } catch (error) {
      console.log('REGISTER ERROR:', error);
      dispatch(userActions.register.failure(error));
      throw error;
    }
  },
  loadUsers: () => async dispatch => {
    dispatch(userActions.loadUsers.start());
    try {
      const users = await loadUsers();
      dispatch(userActions.loadUsers.success(users));
      return users;
    } catch (error) {
      console.log('LOAD USERS ERROR:', error);
      dispatch(userActions.loadUsers.failure(error));
      throw error;
    }
  },
  loadUser: (email) => async () => {
    try {
      const user = await loadUser(email);
      return user;
    } catch (error) {
      console.log('LOAD USER ERROR:', error);
      throw error;
    }
  },
  updatePayUser: (data) => async () => {
    try {
      const user = await updatePayUser(data);
      return user;
    } catch (error) {
      console.log('UPDATE PAY USER ERROR:', error);
      throw error;
    }
  },
  deleteUser: (email) => async () => {
    try {
      const response = await deleteUser(email);
      return response;
    } catch (error) {
      console.log('DELETE USER ERROR:', error);
      throw error;
    }
  }
};
