import moment from 'moment';
import firebase from 'firebase';
//import admin from 'firebase-admin';
import { database } from '../firebase';

const keyPersist = process.env.REACT_APP_KEY_PERSIST;

export async function login({ email, password }) {
  try {
    
    const userCred = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    return userCred.user;
  } catch (error) {
    window.location.replace('/');
    localStorage.removeItem(`persist:${keyPersist}`);
    throw error;
  }
}

export async function resetPassword(email) {
  try {
    await firebase
      .auth()
      .sendPasswordResetEmail(email);
    return true;
  } catch (error) {
    throw error.message;
  }
}

export async function register({ email, password }) {
  try {
    const userCred = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    await database.collection('users').doc(email).set({
      email,
      password,
      lastPayment: moment().toString(),
      createdAt: moment().toString()
    });
    return userCred.user;
  } catch (error) {
    throw error.message;
  }
}

export async function loadUsers () {
  try {
    const snap = await database.collection('users').get();
    const users = snap.docs.map(dc => dc.data());
    return users?.length ? users : [];
  } catch (error) {
    throw error.message;
  }
}

export async function loadUser (email) {
  try {
    const snap = await database.collection('users').doc(email).get();
    const user = snap.data();
    console.log('testtt', user);
    return user;
  } catch (error) {
    throw error.message;
  }

}

export async function updatePayUser ({ email, newDate }) {
  try {
    await database.collection('users').doc(email).update({
      lastPayment: newDate
    });
    return true;
  } catch (error) {
    throw error.message;
  }
}

export async function deleteUser (email) {
  try {
    await database.collection('users').doc(email).delete();
    //const user = admin.auth().getUserByEmail(email);
    //await admin.auth().deleteUser(user.uid);
    return true;
  } catch (error) {
    throw error.message;
  }
}
