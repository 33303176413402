import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { ArrowRight16 } from '@carbon/icons-react';
import { Button, Tile, Form, TextInput, InlineLoading } from 'carbon-components-react';

import { parseError } from '../../utils';
import { thunks as userThunks } from '../../redux/actions/thunks/user';
import {
  login as loginSchema,
  resetPassword as resetPasswordSchema
} from '../../schema';

import './login.scss';

const Login = (props) => {
  const {
    user, login, sendEmailReset
  } = props;
  const history = useHistory();
  const [error, setError] = useState();
  const [emailSent, setEmailSent] = useState();
  const [resetPassword, setResetPassword] = useState();
  const [loading, setLoading] = useState(false);
  const {
    formState: { errors },
    control, handleSubmit
  } = useForm({
    resolver: yupResolver(resetPassword ? resetPasswordSchema : loginSchema)
  });
  useEffect(() => {
    if (user.accessToken) {
      return history.push('/user');
    }
  }, [user.accessToken]);
  const onSubmit = data => {
    setError();
    setLoading(true);
    return login(data)
      .then((email) => {
        setLoading(false);
        return history.push(
          email === 'enmanuelmag@gmail.com' ?
            '/admin' : '/user'
        );
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  };
  const resetPass = data => {
    setError();
    setLoading(true);
    return sendEmailReset(data)
      .then(() => {
        setLoading(false);
        setEmailSent(true);
      })
      .catch(err => {
        setLoading(false);
        setError(err);
      });
  };
  return (
    <div id='Login' className='cd-login-container'>
      <div className='cd-login-cont'>
        <div className='cd-form-cont'>
          <Tile>
            <div className='cd-register-title bpp-mt-32'>
              <h3>{ resetPassword ? 'Reset password' : 'Login' }</h3>
            </div>
            <Form
              noValidate
              onSubmit={handleSubmit(resetPassword ? resetPass : onSubmit)}>
              {
                error &&
                <div className='bpp-form-error bpp-mv-16'>
                  { parseError(error) }
                </div>
              }
              {
                resetPassword ?
                  <>
                    <div className='cd-mt-32'>
                      <Controller
                        name='email'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                          <TextInput
                            id='email'
                            type='text'
                            labelText={'Email'}
                            placeholder='example@email.com'
                            invalid={!!errors.email}
                            invalidText={errors.email?.message}
                            {...field}
                          />
                        )}/>
                    </div>
                    {
                      emailSent &&
                      <p className='cd-mt-16'>
                        {'An email has been sent to your email address with a link to reset your password.'}
                      </p>
                    } 
                  </> :
                  <>
                    <div className='cd-mt-32'>
                      <Controller
                        name='email'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                          <TextInput
                            id='email'
                            type='text'
                            labelText={'Email'}
                            placeholder='example@email.com'
                            invalid={!!errors.email}
                            invalidText={errors.email?.message}
                            {...field}
                          />
                        )}/>
                    </div>
                    <div className='cd-mt-32'>
                      <Controller
                        name='password'
                        control={control}
                        defaultValue=''
                        render={({ field }) => (
                          <TextInput
                            id='password'
                            type='password'
                            labelText={'Password'}
                            placeholder='Insert code sent to your email'
                            invalid={!!errors.password}
                            invalidText={errors.password?.message}
                            {...field}
                          />
                        )}/>
                    </div>
                  </>
              }
              <div className='cd-mt-32'>
                {
                  loading ?
                    <InlineLoading description="Loading..." /> :
                    <Button
                      type='submit'
                      kind='primary'
                      renderIcon={ArrowRight16}>
                      { resetPassword ? 'Reset password' : 'Login' }
                    </Button>
                }
              </div>
            </Form>
          </Tile>
          {
            !resetPassword &&
            <div className='cd-mt-16'>
              <div className='cd-mt-8 cd-line-top cd-ph-16'>
              Forgot password? <a href='#' onClick={() => setResetPassword(true)}>Reset password</a>
              </div>
            </div>
          }
        </div>
        <div className='cd-mt-16'>
          Need help? <a href='https://wa.me/593984236065'>Send me a Whatsapp</a>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  login: userThunks.login,
  sendEmailReset: userThunks.sendEmailReset
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
