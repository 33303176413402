import * as yup from 'yup';

const login = yup.object().shape({
  email: yup.string().email('Must be a email').required('Email is required'),
  password: yup.string().required('Password is required')
});

const resetPassword = yup.object().shape({
  email: yup.string().email('Must be a email').required('Email is required')
});

const code = yup.object().shape({
  code: yup.string().required('Code is required')
});

const register = yup.object().shape({
  email: yup.string().email('Must be a email').required('Email is required'),
  password: yup.string().required('Password is required')
});

export {
  code,
  login,
  register,
  resetPassword
};