import firebase from 'firebase';

import 'firebase/analytics';

import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDy1ZTb4hJUzQ7aH6N6AxqzoUcCnmlrqmM',
  authDomain: 'tracktify.firebaseapp.com',
  databaseURL: 'https://tracktify.firebaseio.com',
  projectId: 'tracktify',
  storageBucket: 'tracktify.appspot.com',
  messagingSenderId: '934989970590',
  appId: '1:934989970590:web:70ac852185a14ac1144f8f',
  measurementId: 'G-ZBJ90SXF8G'
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

const database = firebase.firestore();
database.settings({
  merge: true,
  ignoreUndefinedProperties: true
});


export {
  database
};

