import React from 'react';
import { ToastNotification } from 'carbon-components-react';
import Transition from 'react-transition-group/Transition';

const duration = 300;

const defaultStyle = {
  position: 'fixed',
  bottom: '1rem',
  left: '-400px',
  zIndex: '1000'
};

const transitionStyles = {
  entered: {
    transform: 'translateX(450px)',
    transition: `transform ${duration}ms ease-in-out`
  },
  exiting: {
    transform: 'translateX(-100%)',
    transition: `transform ${duration}ms ease-in-out`
  },
  exited: {
    left: '-400px'
  }
};

const Notification = ({
  show = null, title = null, kind = 'info', subtitle = null, caption = null
}) => {
  return (
    <Transition in={show} timeout={duration} unmountOnExit>
      {(state) => (
        <div style={{
          ...defaultStyle,
          ...transitionStyles[state]
        }}>
          <ToastNotification
            kind={kind}
            title={title}
            subtitle={subtitle}
            caption={caption}
            hideCloseButton
          />
        </div>
      )}
    </Transition>
  );
};

export default Notification;
