import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Button,
  Column,
  DataTable, Grid, Row, Table, TableBody, TableCell, TableContainer,
  TableHead, TableHeader, TableRow, DataTableSkeleton
} from 'carbon-components-react';

import EditUserDialog from './edit';
import RegisterDialog from './register';
import DeleteDialog from './delete';

import './admin.scss';

import { getDouble, parseError } from '../../utils';
import { actions as userActions } from '../../redux/actions/user';
import { Add16, Delete16, Edit16, Logout16 } from '@carbon/icons-react';
import { thunks as userThunks } from '../../redux/actions/thunks/user';

const headerData = [
  {
    key: 'email',
    header: 'Name'
  },
  {
    key: 'lastPayment',
    header: 'Last payment'
  },
  {
    key: 'doubt',
    header: 'Total doubt'
  },
  {
    key: 'actions',
    header: 'Actions'
  }
];

const DataTableProps = ({
  rowsData, headersData, setEditUser, setDeleteUser
}) => {
  return (
    <DataTable rows={rowsData} headers={headersData} isSortable>
      {({ rows, headers, getHeaderProps, getTableProps }) => (
        <TableContainer title="Users">
          <Table {...getTableProps()} size='normal'>
            <TableHead>
              <TableRow>
                {headers.map((header, i) => (
                  <TableHeader key={`yy-${i}`} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={`uu-${row.id}`}>
                  {row.cells.map((cell, ixd) => (
                    <TableCell key={`uuw-${ixd}`}>
                      {
                        cell.id.endsWith('actions') ?
                          <div className='cd-admin-actions'>
                            <Button
                              onClick={() => setEditUser(row.cells)}
                              renderIcon={Edit16} iconDescription="Edit" hasIconOnly kind="ghost"/>
                            <Button
                              onClick={() => setDeleteUser(row.cells)}
                              renderIcon={Delete16} iconDescription="Remove" hasIconOnly kind="ghost"/>
                          </div> :
                          cell.value
                      }
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
};

const Admin = (props) => {
  const history = useHistory();
  const { loadUsers, logout } = props;
  const [registerDialog, setRegisterDialog] = useState();
  const [loading, setLoading] = useState(true);
  const [errorUsers, setError] = useState();
  const [editUser, seteditUser] = useState();
  const [deleteUser, setDeleteUser] = useState();
  const [rowData, setRowData] = useState([]);
  const [totalDoubt, setTotalDoubt] = useState();
  useEffect(() => {
    setLoading(true);
    loadUsersHanlder();
  }, [loadUsers]);
  const loadUsersHanlder = () => {
    return loadUsers()
      .then((urs) => {
        let doubtT = 0;
        setRowData(urs.map((user, idx) => {
          const lastPayment = moment(user.lastPayment);
          const doubt = getDouble(lastPayment, 1.50);
          doubtT += doubt;
          return {
            ...user,
            id: `${idx}`,
            doubt: `$${doubt}`,
            lastPayment: lastPayment.format('DD/MM/YYYY')
          };
        }));
        setTotalDoubt(doubtT);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };
  const logoutUser = () => {
    logout();
    return history.push('/');
  };
  return (
    <div id='Admin' className='cd-admin-container'>
      <div className='cd-admin-cont'>
        {
          !loadUsers &&
          errorUsers &&
          <div className='cd-form-error cd-mv-16'>
            {parseError(errorUsers)}
          </div>
        }
        <RegisterDialog
          visible={registerDialog}
          loadUsersHanlder={loadUsersHanlder}
          close={() => setRegisterDialog()}/>
        <EditUserDialog
          user={editUser}
          visible={editUser}
          loadUsersHanlder={loadUsersHanlder}
          close={() => seteditUser()}/>
        <DeleteDialog
          user={deleteUser}
          visible={deleteUser}
          loadUsersHanlder={loadUsersHanlder}
          close={() => setDeleteUser()}/>
        {
          loading &&
          !errorUsers &&
          <DataTableSkeleton/>
        } 
        {
          !loading &&
          !errorUsers &&
          <DataTableProps
            setEditUser={seteditUser}
            setDeleteUser={setDeleteUser}
            rowsData={rowData} headersData={headerData} />
        }
        <Grid>
          <Row>
            <Column sm={6} lg={6}>
              <p className='cd-mt-16'>
                <strong>Total doubt: ${totalDoubt}</strong>
              </p>
            </Column>
            <Column sm={6} lg={6} className='cd-btn-add'>
              <Button
                kind='primary'
                onClick={() => setRegisterDialog(true)}
                renderIcon={Add16}>
                Add user
              </Button>
              <Button
                kind='secondary'
                onClick={() => logoutUser()}
                renderIcon={Logout16}>
                  Log out
              </Button>
            </Column>
          </Row>
        </Grid>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: userActions.login.out,
  loadUsers: userThunks.loadUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
