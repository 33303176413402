import { createAction } from 'redux-actions';

export const types = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  
  RESET_PASS_START: 'RESET_PASS',
  RESET_PASS_SUCCESS: 'RESET_PASS_SUCCESS',
  RESET_PASS_FAILURE: 'RESET_PASS_FAILURE',

  REGISTER_START: 'REGISTER_START',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  LOAD_USERS_START: 'LOAD_USERS_START',
  LOAD_USERS_FAILURE: 'LOAD_USERS_FAILURE',
  LOAD_USERS_SUCCESS: 'LOAD_USERS_SUCCESS'
};

export const actions = {
  login: {
    start: createAction(types.LOGIN_START),
    success: createAction(types.LOGIN_SUCCESS, (user) => ({ user })),
    failure: createAction(types.LOGIN_FAILURE, (error) => ({ error })),
    out: createAction(types.LOGOUT)
  },
  sendEmailReset: {
    start: createAction(types.RESET_PASS_START),
    success: createAction(types.RESET_PASS_SUCCESS),
    failure: createAction(types.RESET_PASS_FAILURE, (error) => ({ error }))
  },
  register: {
    start: createAction(types.REGISTER_START),
    success: createAction(types.REGISTER_SUCCESS),
    failure: createAction(types.REGISTER_FAILURE, (error) => ({ error }))
  },
  loadUsers: {
    start: createAction(types.LOAD_USERS_START),
    success: createAction(types.LOAD_USERS_SUCCESS, (users) => ({ users })),
    failure: createAction(types.LOAD_USERS_FAILURE, (error) => ({ error }))
  }
};
