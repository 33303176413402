import { types } from '../actions/user';
const keyPersist = process.env.REACT_APP_KEY_PERSIST;

const {
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_START,
  LOGOUT
} = types;

const initialState = {
  error: null,
  loading: false
};

const startLogin = (state) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const errorLogin = (state, action) => {
  return {
    ...state,
    error: action.payload,
    loading: false
  };
};

const completeLogin = (state, action) => {
  const { user } = action.payload;
  return {
    ...state,
    ...user
  };
};

const clearUserState = () => {
  localStorage.removeItem(`persist:${keyPersist}`);
  return {
    ...initialState
  };
};

const startReset = (state) => {
  return {
    ...state,
    error: null,
    loading: true
  };
};

const errorReset = (state, action) => {
  return {
    ...state,
    error: action.payload,
    loading: false
  };
};

const completeReset = (state) => {
  return {
    ...state
  };
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
  case LOGIN_START:
    return startLogin(state, action);
  case LOGIN_FAILURE:
    return errorLogin(state, action);
  case LOGIN_SUCCESS:
    return completeLogin(state, action);
  case LOGOUT:
    return clearUserState();
  case types.RESET_PASS_START:
    return startReset(state, action);
  case types.RESET_PASS_FAILURE:
    return errorReset(state, action);
  case types.RESET_PASS_SUCCESS:
    return completeReset(state, action);
  default:
    return state;
  }
}