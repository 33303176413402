import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tile } from 'carbon-components-react';
import { Logout16 } from '@carbon/icons-react';
import { 
  InlineLoading,
  Grid, Row, Column, Button
} from 'carbon-components-react';

import styled from 'styled-components';
import { heading03 } from '@carbon/type';

import { parseError } from '../../utils';
import Notification from '../shared/notification';
import { actions as userActions } from '../../redux/actions/user';
import { thunks as userThunks } from '../../redux/actions/thunks/user';
import './user.scss';

const Title = styled.h3(heading03);

const payMonth = 1.50;


const User = (props) => {
  const history = useHistory();
  const { logout, sendEmailReset, loadUser } = props;
  const [user, setUser] = useState(props.user);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [sentEmail, setSentEmail] = useState(false);
  const [errorEmail, setErrorEmail] = useState();
  useEffect(() => {
    console.log('test');
    loadUser(user.email)
      .then(usr => {
        setUser({
          ...user,
          ...usr
        });
      })
      .catch(err => {
        setError(err);
      });
  }, []);
  const getDouble = (lastPayment) => {
    return `${(moment().diff(lastPayment, 'months')) * payMonth}`;
  };
  const resetPassword = () => {
    setError();
    setErrorEmail();
    setLoading(true);
    console.log(user.email);
    return sendEmailReset(user.email)
      .then(() => {
        setError();
        setLoading(false);
        setSentEmail(true);
      })
      .catch((err) => {
        setErrorEmail(err);
        setLoading(false);
        setSentEmail(true);
      })
      .finally(() => setTimeout(() => setSentEmail(false), 5000));
  };
  const logoutUser = () => {
    logout();
    return history.push('/');
  };
  const isMobile = window.innerWidth < 768;
  return (
    <>
      <Notification 
        show={sentEmail}
        kind={errorEmail ? 'error' : 'success'}
        title={errorEmail ? 'Error' : 'Success'}
        subtitle={errorEmail ? parseError(errorEmail) : 'Check your email for a reset password link'}
      />
      <div id='User' className='cd-user-container'>
        <div className={isMobile ?'cd-user-cont-full': 'cd-user-cont'}>
          <Tile>
            <Grid className='cd-mv-32'>
              <Row className='cd-mb-16'>
                <Column>
                  <Title>Hi {user.email}!</Title>
                  {
                    error &&
                <div className='bpp-form-error bpp-mv-16'>
                  { parseError(error) }
                </div>
                  }
                </Column>
              </Row>
              <Row>
                <Column sm={12} lg={6}>
                  <div>
                    <p><strong>Your info</strong></p>
                    <p>Your last payment was on {moment(user?.lastPayment)?.format?.('MMMM Do YYYY')}</p>
                    <p>Amount to pay month on month ${payMonth}</p>
                    <p>
                      {'Your total doubt is: '}
                    ${getDouble(user?.lastPayment)}
                    </p>
                  </div>
                </Column>
                <Column sm={12} lg={6}>
                  <div>
                    <p><strong>Deposit info</strong></p>
                    <p className='cd-label cd-mb-4'>
                      {'Help me to buy a RTX 4090 🕹️ and new power supply of 1000 watts'}
                    </p>
                    <p>Bank: Banco Bolivariano</p>
                    <p>Account type: Savings</p>
                    <p>Account number: 1101273860</p>
                    <p>Account holder: Enmanuel Magallanes Pinargote</p>
                    <p>Email: enmanuelmag@gmail.com</p>
                  </div>
                </Column>
              </Row>
            </Grid>
            <Grid className='cd-line-top cd-ph-16'>
              <Row className='cd-mt-16'>
                <Column sm={6}>
                  <Column lg={12}>
                    <p>{'Notify a new payment? '}
                      <a href='https://wa.me/593984236065'>Send me a Whatsapp</a>
                    </p>
                  </Column>
                  <Column lg={12} className='cd-mt-4'>
                    <p
                      onClick={resetPassword}>
                      {
                        loading ?
                          <InlineLoading description="Loading..." /> :
                          <>{'Forgot password? '}<a href='#'>Send a email to reset it</a></>
                      }
                    </p>
                  </Column>
                </Column>
                <Column lg={6} className='cd-btn-out'>
                  <Button
                    kind='primary'
                    onClick={() => logoutUser()}
                    renderIcon={Logout16}>
                  Log out
                  </Button>
                </Column>
              </Row>
            </Grid>
          </Tile>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  logout: userActions.login.out,
  sendEmailReset: userThunks.sendEmailReset,
  loadUser: userThunks.loadUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(User);
